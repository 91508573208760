<template>
  <v-card :loading="loading">
    <v-card-title class="justify-space-between">
      <span>Telegram Notification</span>
      <v-tooltip
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>You can receive realtime push notification via telegram</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-list two-line>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon class="background_color">
              mdi-telegram
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Status</v-list-item-subtitle>
            <v-list-item-title>{{ user.telegram_chat_id? 'Active' : 'Inactive' }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              v-if="!user.telegram_chat_id"
              color="success"
              @click.stop="requestTelegram"
            >
              <v-icon left>
                mdi-link-variant-plus
              </v-icon>
              <span>Connect</span>
            </v-btn>
            <v-btn
              v-else
              color="error"
              @click.stop="revokeTelegram"
            >
              <v-icon left>
                mdi-link-variant-off
              </v-icon>
              <span>Revoke</span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-alert
        v-if="requestTelegramData"
        icon="mdi-shield-lock-outline"
        text
        type="info"
      >
        Your browser will be redirected to telegram app. If nothing happen, <a
          class="font-weight-black"
          :href="requestTelegramData"
          target="_blank"
        >click this link</a>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'UserTelegram',
  props: {
    user: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      requestTelegramData: null,
    };
  },

  methods: {
    async requestTelegram() {
      try {
        this.loading = true;
        const response = await this.$api.requestTelegram();
        this.requestTelegramData = response.data.data.link;
        window.open(
          this.requestTelegramData,
          '_blank',
        );
      } catch (error) {
        this.$toast.error('Failed request telegram');
      } finally {
        this.loading = false;
      }
    },

    async revokeTelegram() {
      try {
        this.loading = true;
        await this.$api.revokeTelegram();

        // update vuex profile
        this.$store.dispatch('getProfile');
      } catch (error) {
        this.$toast.error('Failed revoke telegram');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>

</style>
