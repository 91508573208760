<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-card-title>
            My Profile
          </v-card-title>
          <v-card-text>
            <v-alert
              v-if="user.should_change_password"
              prominent
              text
              type="warning"
            >
              <v-row align="center">
                <v-col class="grow">
                  Your account is still using default password. Consider changing your password.
                </v-col>
              </v-row>
            </v-alert>
            <v-list two-line>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="background_color">
                    mdi-account-circle
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>Name</v-list-item-subtitle>
                  <v-list-item-title>{{ user.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="background_color">
                    mdi-email
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>Email</v-list-item-subtitle>
                  <v-list-item-title>{{ user.email }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="background_color">
                    mdi-shield-check
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>Account Level</v-list-item-subtitle>
                  <v-list-item-title v-if="user.is_superadmin">
                    Superadmin
                  </v-list-item-title>
                  <v-list-item-title v-else-if="user.is_organization_admin">
                    Organization Admin
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    User
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-dialog
              v-model="dialog"
              max-width="500px"
              persistent
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  class="ma-1"
                  color="primary"
                  v-on="on"
                  @click="editProfile"
                >
                  Update Profile
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span>Update</span>
                  <v-spacer />
                  <v-icon
                    color="white"
                    @click="closeDialog"
                  >
                    mdi-close
                  </v-icon>
                </v-card-title>
                <v-tabs v-model="tab">
                  <v-tab
                    v-for="item in tabItems"
                    :key="item.title"
                  >
                    {{ item.title }}
                  </v-tab>
                </v-tabs>

                <ErrorAlert />

                <v-tabs-items v-model="tab">
                  <v-tab-item key="profile">
                    <v-card-text class="pt-5 pb-0">
                      <v-form
                        ref="form"
                        v-model="formValidity"
                      >
                        <v-text-field
                          v-model="form.name"
                          :rules="nameRules"
                          :disabled="loading"
                          :counter="30"
                          outlined
                          dense
                          label="Name"
                          @keypress.enter="saveItem"
                        />
                        <v-text-field
                          v-model="form.email"
                          :rules="emailRules"
                          :disabled="loading"
                          outlined
                          dense
                          label="Email"
                          @keypress.enter="saveItem"
                        />
                      </v-form>
                    </v-card-text>
                  </v-tab-item>
                  <v-tab-item key="password">
                    <v-card-text class="pt-5 pb-0">
                      <v-form
                        ref="formPassword"
                        v-model="formPasswordValidity"
                      >
                        <v-text-field
                          v-model="formPassword.old"
                          :rules="passwordRules"
                          :disabled="loading"
                          type="password"
                          outlined
                          dense
                          label="Old Password"
                          @keypress.enter="saveItem"
                        />
                        <v-text-field
                          v-model="formPassword.new"
                          :rules="passwordRules"
                          :disabled="loading"
                          type="password"
                          outlined
                          dense
                          label="New Password"
                          @keypress.enter="saveItem"
                        />
                        <v-text-field
                          v-model="formPassword.confirm"
                          :rules="passwordRules"
                          :disabled="loading"
                          type="password"
                          outlined
                          dense
                          label="Confirm Password"
                          @keypress.enter="saveItem"
                        />
                      </v-form>
                    </v-card-text>
                  </v-tab-item>
                </v-tabs-items>

                <v-card-actions class="pa-3">
                  <v-spacer />
                  <v-btn
                    text
                    :disabled="loading"
                    @click="closeDialog"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    class="primary"
                    :loading="loading"
                    @click="saveItem"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <UserOrganization :user="user" />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <UserTelegram :user="user" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { camelToSentence, handleError } from '@/utils/utils';
import * as types from '@/store/mutation-types';
import UserTelegram from './UserTelegram.vue';
import UserOrganization from './UserOrganization.vue';

export default {
  name: 'UserProfile',
  components: { UserTelegram, UserOrganization },
  data() {
    return {
      dialog: false,
      loading: false,
      formValidity: false,
      formPasswordValidity: false,
      form: {
        name: '',
        email: '',
      },
      defaultForm: {
        name: '',
        email: '',
      },
      formPassword: {
        old: '',
        new: '',
        confirm: '',
      },
      defaultFormPassword: {
        old: '',
        new: '',
        confirm: '',
      },
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 30 || 'Name must be less than 30 characters',
      ],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
      ],
      tab: null,
      tabItems: [
        {
          title: 'profile',
        },
        {
          title: 'password',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['user']),
  },

  watch: {
    dialog(val) {
      // Reset errors in store
      this.$store.commit(types.ERROR, null);

      if (!val) {
        this.closeDialog();
      }
    },
  },

  created() {
    this.$store.dispatch('getProfile');
  },

  methods: {
    camelToSentence,

    closeDialog() {
      this.dialog = false;
      setTimeout(() => {
        if (this.tab === 0) {
          // reset profile validation
          this.$refs.form.resetValidation();
        } else {
          // reset password validation
          this.$refs.formPassword.resetValidation();
        }
        this.form = { ...this.defaultForm };
        this.formPassword = { ...this.defaultFormPassword };
      }, 300);
    },

    async saveItem() {
      if (this.tab === 0) {
        // validate profile
        this.$refs.form.validate();
        if (!this.formValidity) return;
      } else {
        // validate password
        this.$refs.formPassword.validate();
        if (!this.formPasswordValidity) return;
      }

      this.loading = true;

      try {
        if (this.tab === 0) {
          // send profile data
          await this.$api.updateProfile(this.form);
          this.$toast.success('Success Update Profile');
        } else {
          // send password data
          await this.$api.updatePassword({
            old_password: this.formPassword.old,
            new_password: this.formPassword.new,
            new_password_confirmation: this.formPassword.confirm,
          });
          this.$toast.success('Success Update Password');
        }

        this.closeDialog();
        // reload get profile from API
        this.$store.dispatch('getProfile');
      } catch (error) {
        handleError(error);
        this.$toast.error('Failed Update Data');
      }

      setTimeout(() => {
        this.loading = false;
      }, 300);
    },

    editProfile() {
      this.form = { ...this.user };
      this.dialog = true;
    },
  },
};
</script>
