<template>
  <v-card>
    <v-card-title>
      My Organization
    </v-card-title>

    <v-card-text>
      <v-list
        v-if="user.organization"
        two-line
      >
        <v-list-item>
          <v-list-item-avatar>
            <v-icon class="background_color">
              mdi-domain
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.organization.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.organization.description }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  icon
                  :to="`/organizations/${user.organization.id}`"
                  v-on="on"
                >
                  <v-icon>mdi-login</v-icon>
                </v-btn>
              </template>
              <span>Go to Organization</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <p
        v-else
        class="text-muted text-center my-5"
      >
        You have no organization
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'UserOrganization',
  props: {
    user: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style>

</style>
